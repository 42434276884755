(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name referees.referee.controller:RefereeCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.votacio')
    .controller('votaciopublicCtrl', votaciopublicCtrl);

  function votaciopublicCtrl(resultat,perfil, $state,$scope,equip,valoraciones,items,partit, valoracions)
  {
     var vm=this;
    // vm.items=items.slice(0,items.length-1);
    vm.items=items;
    vm.show=false;
    if(resultat.length===1){
       var r={
       fecha:resultat[0].fecha,
       campionat:resultat[0].campionat,
        elocal:resultat[0].elocal,
    evisitant:resultat[0].evisitant,
    cc:[0].cc,
       pp:0,
       ptg:0,
       ptt:0,
       pte:0,
       pdt:0,
       pdp:0,
       marcador:0,
       verdplay:0,
       tv:0,
       puntsVisitant:0,totpunts:0,
       idEquipo:resultat[0].idVisitant,
       idPartido:resultat[0].idPartido,
       idLocal:resultat[0].idLocal,
       idVisitant:resultat[0].idVisitant
          };
       
       resultat.push(r);
   }
     vm.observacions=items[items.length-1].valor;
     if (vm.observacions===0){
         vm.observacions="";
     }
     vm.itempos=[];
     vm.valoracions=valoraciones;
    
     vm.perfil=perfil;
    
      $scope.opcions=[];
      $scope.valors=[];
 //     $scope.opcions[0]= [{ id: 1, name: "Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
 //     $scope.opcions[1]= [{ id: 1, name: "2Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
//      $scope.opcions[2]= [{ id: 1, name: "3Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
//      $scope.opcions[3]= [{ id: 1, name: "4Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
//  $scope.opcions[4]= [{ id: 1, name: "5Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
////  $scope.opcions[5]= [{ id: 1, name: "6Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
//  $scope.opcions[6]= [{ id: 1, name: "7Gairebé mai"},{ id: 2, name: "Gairebé mai"},{ id: 3, name: "Gairebé mai"},{ id: 4, name: "Gairebé mai"}];
 $scope.item4={iditem:0,nom:"",respetoa:""};
 $scope.opcions4=[];
 var valorspos4=[];
 var valorscsv4=[];
 $scope.item5={iditem:0,nom:"",respetoa:""};
 $scope.opcions5=[];
 var valorspos5=[];
 var valorscsv5=[];
 $scope.item6={iditem:0,nom:"",respetoa:""};
 $scope.opcions6=[];
 var valorspos6=[];
 var valorscsv6=[];
 $scope.item7={iditem:0,nom:"",respetoa:""};
 $scope.opcions7=[];
 var valorspos7=[];
 var valorscsv7=[];
 $scope.item14={iditem:0,nom:"",respetoa:""};
 $scope.opcions14=[];
 var valorspos14=[];
 var valorscsv14=[];
 $scope.item15={iditem:0,nom:"",respetoa:""};
 $scope.opcions15=[];
 var valorspos15=[];
 var valorscsv15=[];
 $scope.item16={iditem:0,nom:"",respetoa:""};
 $scope.opcions16=[];
 var valorspos16=[];
 var valorscsv16=[];

    for(var j=0;j<vm.items.length;j++)
     {    
       
         if(vm.items[j].iditem===4){
             $scope.item4.iditem=4;
             $scope.item4.nom=vm.items[j].nom;
             $scope.item4.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions4[i]={id:i,name:a[i],selec:0};
             valorspos4[i]=valors[i];
             valorscsv4[i]=a[i];
         }
         }
         if(vm.items[j].iditem===5){
             $scope.item5.iditem=5;
             $scope.item5.nom=vm.items[j].nom;
             $scope.item5.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions5[i]={id:i,name:a[i],selec:0};
             valorspos5[i]=valors[i];
             valorscsv5[i]=a[i];
         }
         }
         if(vm.items[j].iditem===6){
             $scope.item6.iditem=6;
             $scope.item6.nom=vm.items[j].nom;
             $scope.item6.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions6[i]={id:i,name:a[i],selec:0};
             valorspos6[i]=valors[i];
             valorscsv6[i]=a[i];
         }
         }
        if(vm.items[j].iditem===7){
             $scope.item7.iditem=7;
             $scope.item7.nom=vm.items[j].nom;
             $scope.item7.respetoa=vm.items[j].respestoa;
            var a=vm.items[j].valorsCSV.split(";"); 
            var valors=vm.items[j].valoresposbiles.split(";");
               for(var i=0;i<a.length;i++){
             $scope.opcions7[i]={id:i,name:a[i],selec:0};
             valorspos7[i]=valors[i];
             valorscsv7[i]=a[i];
         }
         }
        
      
       
         
         var a=vm.items[j].valorsCSV.split(";"); 
         var valors=vm.items[j].valoresposbiles.split(";");
         var c=[];
         var valorspos=[];
         var seleccionat=[];
         if ((vm.items[j].iditem===35)||(vm.items[j].iditem===36)||(vm.items[j].iditem===42)||(vm.items[j].iditem===43)){
             for(var i=0;i<100;i++){
             c[i]={id:i,name:i.toString(),selec:0};
             valorspos[i]=i.toString();
         }
         }else{
         for(var i=0;i<a.length;i++){
             c[i]={id:i,name:a[i],selec:0};
             valorspos[i]=valors[i];
         }}
    //$scope.opcions[j]([{ id: 1, name: "Gairebé mai"}, { id: 2, name:"Algunes vegades"}, { id: 3, name:"Sovint"}, { id: 4, name:"Gairebé sempre"}]);
 $scope.opcions[j]=c;
 $scope.valors[j]=valorspos;
 
 
      }
      $scope.seleccionat=[];
      var d=0;
      
     var  local=0;
   var visitant=1;
  
   
   
   if (resultat[0].idEquipo===resultat[0].idLocal){
       local=0;
       visitant=1;
   } else{
       local=1;
       visitant=0;
   }
    
    vm.campionat=resultat[local].campionat;
    vm.data=resultat[local].fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');;
    vm.elocal=resultat[local].elocal;
    vm.evisitant=resultat[local].evisitant;  
    vm.cc=resultat[local].cc;
    vm.ppl=resultat[local].pp;
    vm.ppv=resultat[visitant].pp;
     vm.ptgl=resultat[local].ptg;
    vm.ptgv=resultat[visitant].ptg;
     vm.pttl=resultat[local].ptt;
    vm.pttv=resultat[visitant].ptt;
     vm.ptel=resultat[local].pte;
    vm.ptev=resultat[visitant].pte;
      vm.pdtl=resultat[local].pdt;
    vm.pdtv=resultat[visitant].pdt;
      vm.pdpl=resultat[local].pdp;
    vm.pdpv=resultat[visitant].pdp;
     vm.marcadorl=resultat[local].marcador;
    vm.marcadorv=resultat[visitant].marcador;
    vm.pvpl=resultat[local].verdplay;
    vm.pvpv=resultat[visitant].verdplay;
     vm.tvl=resultat[local].tv;
    vm.tvv=resultat[visitant].tv;
    vm.pl=resultat[local].puntsLocal;
     vm.pv=resultat[visitant].puntsVisitant;
    if (resultat[local].suspendido){
        vm.suspes="PARTIT SUSPES!!";
    }else{
         vm.suspes=resultat[local].totpunts+ " - "+resultat[visitant].totpunts;
    }
  
  if (perfil==='1') $scope.perfilnom="Tutor/a de grada";
  if (perfil==='2') $scope.perfilnom="Públic";
  if (perfil==='4') $scope.perfilnom="Tècnic/a";
  if (perfil==='5') $scope.perfilnom="Tutor/a de joc";

  if (equip===resultat[0].idLocal.toString()){
      $scope.equipnom=vm.evisitant;
  }else{
      $scope.equipnom=vm.elocal;
  }
 vm.click=function (){
     var r=true;
      
               if(r===true)
        {
  
              for(var j=0;j<vm.items.length;j++)
     {    
            var v={idpartido:0,iditemvalorado:0,vporc:0,vmaximo:0,vvalor:0.00,idperfil:0,idusuario:0,idequipo:0,observaciones:'',idconsell:2};
             
             v.idpartido=resultat[local].idPartido;
             v.iditemvalorado=vm.items[j].iditem;
             v.idperfil=2;
             v.idusuario=0;
             //alert(vm.items[j].iditem);
             v.idconsell=2;
             v.idequipo=equip;
            
            
         
        
           if(vm.items[j].iditem===4){
                  var i=valorscsv4.indexOf($scope.select4);
                
                     v.vporc=i*100; 
                     v.vvalor=(i+1)*12.5;
                     v.observaciones="";
                     v.vmaximo=25;
                      
                 
                    }
                    
             if(vm.items[j].iditem===5){
                  var i=valorscsv5.indexOf($scope.select5);
                     v.vporc=i*100; 
                     v.vvalor=(i+1)*12.5;
                     v.observaciones="";
                     v.vmaximo=25;
                     
              
                    }
           if(vm.items[j].iditem===6){
                  var i=valorscsv6.indexOf($scope.select6);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*6.25;
                     v.observaciones="";
                     v.vmaximo=25;
                
                    }
                    
          if(vm.items[j].iditem===7){
                  var i=valorscsv7.indexOf($scope.select7);
                     v.vporc=(i+1)*25; 
                     v.vvalor=(i+1)*6.25;
                     v.observaciones="";
                     v.vmaximo=25;
                
                    }
            
            
          
                    
           
          
           var  fecha=new Date();
        //   v.fecha_hora=fecha.getFullYear()+"-"+(fecha.getMonth() + 1) + "-" + fecha.getDate() + " " + fecha.getHours() + ":" + fecha.getMinutes() + ":" + fecha.getSeconds();
        
             valoracions.insert({idpartit: v.idpartido,idequip:v.idequipo,idperfil:v.idperfil}, v);
             
              } 
              
          var url = $state.href("home.resultats",{idPartit: v.idpartido});
           window.open(url,'_self');
          }
          
          
          
          
  
 };  
 }}());